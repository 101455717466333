import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { Context } from ".."

import styles from "../styles/Home.module.scss"

const NotFoundPage: FC = () => {

  const { store } = useContext(Context)

  const [anim1, setAnim1] = useState(false)

  useEffect(() => {
    window.location.href = "/studios"
  }, [])

  return (
    <></>
  )
}

export default observer(NotFoundPage)