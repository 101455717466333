import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import ImageSlider from "../components/ImageSlider"
import { Context } from ".."

import styles from "../styles/Acceptance.module.scss"
import useWindowDimensions from "../core/hooks/useWindowDimensions"

const UserAgreement: FC = () => {

  const { store, adminStore } = useContext(Context)
  const { width } = useWindowDimensions()

  useEffect(() => {
    return () => {
      
    }
  }, [])

  return (
    <>
      <div className={styles.policy}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.wrapper}>
                <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
                <h2>(договор публичной оферты)</h2>
                <h3>PODCASTBERY</h3>

                {/* <p className={styles.addr}>Настоящий документ представляет собой предложение (публичную оферту) ИП Казанцева Владислава Дмитриевича (ИНН 245210737200, ОГРИП 318246800016960) (далее – «Оператор») заключить агентский договор на условиях, изложенных в настоящем документе (далее – «Оферта»).</p> */}

                <div className={styles.points}>
                  <p>
                    <b>ОСНОВНЫЕ ПОНЯТИЯ</b>
                    <b>Оператор</b> - ИП Казанцев Владислав Дмитриевич (ИНН 245210737200, ОГРИП 318246800016960).<br/>
                    <b>Пользователь</b> - дееспособное физическое лицо, достигшее 18 лет, имеющее законное право вступать в договорные отношения с Оператором, в том числе бронировать услуги на сайте https://podcastbery.ru/.<br/>
                    <b>Сайт</b> - информационный ресурс Оператора в интернете, расположенный по адресу <a href="https://podcastbery.ru/">https://podcastbery.ru/</a>, через который осуществляется непосредственный заказ услуг.<br/>
                    <b>Целевое назначение Сайта</b> - предоставление Пользователям технической возможности пользоваться Системой бронирования.<br/>
                    <b>Заказ</b> - должным образом оформленный запрос Пользователя на получение услуг по бронированию Локаций, а также дополнительных услуг, выбранных на Сайте.<br/>
                    <b>Система бронирования</b> - информационная система, содержащая информацию, касающуюся наличия свободных часов в Локациях, а также дополнительных услуг, тарифов и правил их применения, в том числе условий предоставления предлагаемых услуг. Информация в Системе бронирования размещается на Сайте непосредственными поставщиками данных услуг или их полномочными представителями. Информация в системе бронирования может в любой момент быть изменена или дополнена. Система бронирования также может быть размещена непосредственно на сайте Поставщика услуг.<br/>
                    <b>Локация</b> - пространство, информация о котором оформлена Поставщиком услуг в соответствии с требованиями Сайта, и размещена Оператором на Сайте для осуществления дальнейшего его бронирования Пользователями.<br/>
                    <b>Письмо с подтверждением</b> - документ, который отправляется Оператором на электронную почту Пользователя и (или) в мессенджер по номеру телефона Пользователя, указанные при регистрации, подтверждающий право Пользователя на аренду Локации, оказание услуг Поставщиком, на условиях, выбранных Пользователем, согласно оформленному Заказу при соблюдении условий Поставщика услуг, в том числе по их предварительной оплате.<br/>
                    <b>Поставщик услуг</b> - лицо, непосредственно оказывающее услуги.<br/>
                    <b>Материалы</b> - текст и/или изображение.
                  </p>
                  <p>
                    <b>1. Общие положения</b><br/>
                    1.1. Заказывая услуги через Оператора, Пользователь соглашается с условиями настоящего Пользовательского соглашения, являющийся договором публичной оферты на предоставление услуг по бронированию Локаций, а также иных услуг Поставщика (далее – Договор).<br/>
                    1.2. Оператор оказывает Пользователю услуги по предоставлению содержащейся в Системе бронирования информации и бронированию Локации и услуг третьих лиц (Поставщиков услуг), в соответствии с заданными Пользователем параметрами (дата, время, длительность бронирования, тип помещения, оборудования и т.д.), а также по оформлению отказа от забронированных услуг по соответствующему заявлению Пользователя.<br/>
                    1.3. Настоящий Договор, а также приложения, относящиеся к нему, представленные на Сайте, являются публичной офертой в соответствии со ст. 435 и п. 2 ст. 437 ГК РФ. В соответствии с пунктом 3 статьи 438 ГК РФ, принятие содержащихся в настоящей оферте существенных условий, трактуемое в соответствии с пунктом 1 статьи 438 ГК РФ не иначе как акцепт, равносильно заключению договора на оказание услуг по бронированию услуг Поставщика. Полным и безоговорочным принятием (акцептом) условий настоящей оферты считается проставление Пользователем специальной отметки (галочки) в соответствующем поле при регистрации Пользователя на Сайте, и/или при оформлении Пользователем Заказа на Сайте.<br/>
                    1.4. Пользователь соглашается с Договором путем проставления отметки в виде «галочки» в графе «Согласен с условиями Пользовательского соглашения» на последнем этапе регистрации на Сайте и/или при оформлении Заказа на Сайте. Соглашаясь с условиями настоящего Договора, Пользователь подтверждает свое право- и дееспособность, финансовую состоятельность, а также сознаёт ответственность за обязательства, возложенные на него в результате заключения настоящего Договора. Пользователь подтверждает достоверность своих личных данных, а также данных лиц, указанных в Заказе и принимает на себя всю ответственность за их точность, полноту и достоверность.<br/>
                    Пользователь принимает на себя все возможные коммерческие риски (оформление нового заказа, изменение тарифа, возврат денег и проч.), связанные с его действиями по допущению ошибок, неточностей в предоставлении, запрашиваемых Оператором при оформлении Заказа, данных.<br/>
                    1.5. К отношениям между Пользователем и Оператором применяются положения действующего законодательства Российской Федерации.<br/>
                    Забронированные Пользователем услуги третьих лиц регулируются правилами соответствующего Поставщика услуг и правилами применяемого тарифа. <br/>
                    1.6. Оператор не выступает в качестве лица, реализующего услуги, предлагаемые на Сайте.
                  </p>
                  <p>
                    <b>2. Условия и порядок исполнения договора.</b>
                    <b>2.1. Регистрация на сайте:</b><br/>
                    2.1.1. При оформлении заказа Пользователь регистрируется на сайте, путем заполнения регистрационной анкеты и введения своих контактных данных. Регистрация производится на номер телефона, указанный Пользователем в соответствующем поле, который в дальнейшем будет использован в качестве логина к личному кабинету Пользователя на Сайте. При этом пароль к личному кабинету на Сайте Пользователь формирует самостоятельно.<br/>
                    2.1.2. Пользователь обязуется не сообщать третьим лицам логин и пароль, указанные при регистрации. В случае возникновения у Пользователя подозрений относительно безопасности его логина и пароля или возможности их несанкционированного использования третьими лицами, Пользователь обязуется незамедлительно уведомить об этом Оператора, направив электронное письмо по адресу <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>.<br/>
                    2.1.3. Весь обмен информацией между Сторонами относительно Заказа и выполнения прочих своих обязательств по настоящему Договору осуществляется через электронную почту, адрес которой указан Пользователем при регистрации, а также личный кабинет, который формируется после регистрации на сайте. В связи с этим, Пользователь обязуется регулярно, вплоть до момента оказания услуг Поставщиком, аренды Локации, самостоятельно отслеживать состояние своего Заказа, в том числе проверять свою электронную почту или личный кабинет на предмет получения информации о возможных изменениях, а при необходимости обращаться за информацией к Оператору через электронную почту info@podcastbery.ru или иную форму обратной связи, предусмотренную на Сайте.<br/>
                    Ответственность за любые последствия, возникающие в связи с отсутствием у Пользователя информации об изменениях в Заказе, произошедших по независящим от Оператора причинам (отмена брони Локации, изменение тарифов и т.д.), несет Пользователь, при условии соблюдения Оператором порядка уведомления Пользователя о любых изменениях в Заказе.<br/>

                    <b>2.2. Оформление заказа:</b><br/>
                    2.2.1. Заказ на услуги Поставщика оформляется Пользователем самостоятельно на Сайте. При этом Пользователь самостоятельно определяет дату бронирования, время начала, длительность, зал и прочие условия предоставляемых услуг.<br/>
                    2.2.2. Пользователь знакомится со всеми условиями Заказа в процессе бронирования. В случае, если Пользователю не понятны какие-либо условия Заказа, в том числе, условия отказа от услуг, внесение любых изменений, в оформленный Заказ, Пользователь должен уточнить необходимую ему информацию у Оператора до завершения оформления Заказа.<br/>
                    2.2.3. При оформлении Заказа Пользователь должен заполнить все поля, указанные в Системе бронирования как «обязательные» для заполнения.<br/>
                    2.2.4. Заказы, оформленные Пользователем на Сайте, носят окончательный характер и подлежат автоматизированной обработке в системе обработки данных Оператора. После поступления от Пользователя в систему обработки данных Оператора оформленного Заказа, Пользователю автоматически направляется на электронный адрес и (или) в мессенджер по указанному при регистрации номеру телефона, Сервисное письмо со статусом Заказа.<br/>
                    2.2.5. Заказы на бронирование времени в Локациях на территории Российской Федерации не требуют дополнительного подтверждения со стороны Поставщика услуг.<br/>
                    Письмо, отправленное на электронную почту Пользователя или в мессенджер, является документом, подтверждающим право на предоставление услуг, выбранных Пользователем при соблюдении условий Поставщика услуг, в том числе по их предварительной оплате. Для доступа в забронированную Локацию может потребоваться оформление дополнительных документов и заявок на пропуск, о чём будет указано на странице Поставщика услуг на Сайте. Оператор не гарантирует допуск Пользователя в забронированную Локацию в случае нарушения Пользователем правил доступа Поставщика услуг.<br/>
                    2.2.6. Все условия Заказа, в том числе внесение любых изменений в оформленные Заказы, отказ от забронированных услуг, равно как и другие условия предоставляемых услуг регламентируются правилами соответствующего Поставщика услуг, действующим законодательством Российской Федерации, применимыми международными нормами.<br/>
                    2.2.7. Время начала и окончания бронирования выбирается Пользователем при оформлении Заказа.<br/>
                    2.2.8. В случае, если Пользователь не явится в забронированную Локацию в течение часа после указанного в Заказе времени без предварительного о том уведомления, Поставщик услуг оставляет за собой право аннулировать бронь, выставив штрафные санкции в соответствии с правилами Поставщика услуг. Размер штрафных санкций, подлежащих применению к Пользователю при наступлении подобного случая, указывается в правилах Поставщика услуг.<br/>

                    <b>2.3. Оплата заказа:</b><br/>
                    2.3.1. Стоимость Заказа указывается на Сайте в режиме онлайн и может быть изменена соответствующим Поставщиком услуг в одностороннем порядке.<br/>
                    Система бронирования рассчитывает стоимость ограниченного набора услуг, которые указаны при оформлении Заказа. В случае получения дополнительных услуг у Поставщика услуг, а также при использовании расходных материалов, Пользователь обязуется произвести дополнительную оплату полученных услуг, расходных материалов, а также внести оставшуюся часть стоимости аренды помещения в случае внесения только частичной оплаты при составлении Заказа.<br/>
                    2.3.2. Срок, условия и порядок оплаты Заказа определяются непосредственно Поставщиком услуг. При необходимости Пользователь обязан внести предоплату Поставщику услуг для завершения бронирования Локации или услуги. <br/>
                    2.3.3. Производя оплату, Пользователь подтверждает, что он ознакомлен и согласен с правилами и тарифами соответствующего Поставщика услуг, который предоставляет выбранную услугу.<br/>

                    <b>2.4. Аннулирование, изменение, отмена заказа</b><br/>
                    2.4.1. Все условия Заказа, в том числе: внесение любых изменений в оформленные Заказы, отказ от услуг, равно как и другие условия оказания услуг регламентируются правилами применения тарифа на данную услугу, установленными соответствующим Поставщиком услуг и договором публичной оферты Оператора.<br/>
                    Пользователь обязуется производить любые изменения подтверждённого Оператором Заказа на аренду Локации только через Оператора.<br/>
                    2.4.2. Изменение лиц в Заказе, даты и времени бронирования, длительности и иных существенных условий Заказа доступно исключительно путем оформления нового Заказа и отмены ранее оформленного.<br/>
                    2.4.3. В случае необходимости, отменить Заказ возможно одним из следующих способов:<br/>
                    Пользователь самостоятельно отменяет Заказ в личном кабинете на Сайте;<br/>
                    Пользователь напрямую связывается с Поставщиком услуг и уведомляет его об отмене Заказа;<br/>
                    Пользователь связывается с Оператором и уведомляет его об отмене Заказа по адресу электронной почты <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>.<br/>
                    2.4.4. Заказ считается отмененным после присвоения ему Оператором статуса «Аннулирован» и направления соответствующего сервисного письма Пользователю.<br/>
                    2.4.5. Информация о сроках изменения и отмены Заказа, размере штрафных санкций за несоблюдение этих сроков и иных мерах ответственности и условиях бронирования определяется правилами Поставщика услуг.<br/>
                    2.4.6. Условия и порядок возврата денежных средств Пользователю определяются Поставщиком услуг. Оператор не несет ответственности за возврат денежных средств, оплаченных Пользователем Поставщику.

                    <b>2.5. Пользователю запрещено:</b><br/>
                    2.5.1. использовать роботы, программы-обходчики, боты и иные автоматизированные средства или процессы для сбора данных или другого контента, а также для доступа к ним и совершения иных действий на Сайте вне зависимости от целей;<br/>
                    2.5.2. предпринимать какие-либо действия, которые наносят ущерб и/или негативно влияют на работоспособность Сайта, а также могут нанести ущерб или негативно повлиять на надлежащее функционирование Сайта.<br/>

                  </p>
                  <p>
                    <b>3. Гарантии и ответственность сторон</b><br/>
                    3.1. Гарантии и ответственность Оператора.<br/>
                    3.1.1. Оператор не несет перед Пользователем ответственности в случае полной или частичной неработоспособности системы и ее компонентов в течение какого-либо времени, а также при отсутствии возможности доступа Пользователя к системе или несения им любых косвенных или прямых затрат в связи с данными обстоятельствами. Все предложения, цены, а также условия продажи могут быть изменены без уведомления Пользователя, ограничены по времени, наличию мест и срокам предварительного заказа, датам, срокам минимального или максимального бронирования в месте аренды, факторами выходных дней и праздников, сезонными колебаниями цен, а также забастовками и временной неработоспособностью систем бронирования и/или подвержены другим изменениям, условиям и ограничениям.<br/>
                    3.1.2. Оператор не несет ответственность за негативные последствия и убытки, возникшие в результате событий и обстоятельств, находящихся вне сферы его компетенции, а также за действия (бездействие) третьих лиц, в том числе, но не ограничиваясь:<br/>
                    - в случае невозможности выполнения принятых на себя обязательств, вследствие недостоверности, недостаточности и несвоевременном предоставлении Пользователем сведений и документов, или нарушения Пользователем условий настоящего Договора или требований к документам;<br/>
                    - за неявку или опоздание Пользователя в Локацию;<br/>
                    - за несоблюдение Пользователем установленных Поставщиком услуг правил поведения;<br/>
                    - за подлинность и правильность оформления документов Пользователя и иных лиц, указанных в Заказе (достоверность содержащихся в них сведений);<br/>
                    - за достоверность сведений об услугах Поставщика услуг, размещаемых на Сайте.<br/>
                    3.2. Пользователь подтверждает и гарантирует, что он ознакомлен и согласен:<br/>
                    - с требованиями к оформлению и наличию документов, требуемых для бронирования времени в Локации, и принимает на себя всю ответственность за подготовку необходимых документов;<br/>
                    - с ценами Поставщика услуг, в том числе с условиями отмены Заказа;<br/>
                    - с правилами Поставщика услуг, предоставляющего выбранную Локации; <br/>
                    - с условиями Договора публичной оферты;<br/>
                    - с требованиями законодательства РФ.<br/>
                    3.3. Стороны освобождаются от ответственности за ненадлежащее исполнение или неисполнение обязательств по настоящему Договору в случае наступления обстоятельств непреодолимой силы, к таковым стороны относят следующие обстоятельства: пожар, эпидемия, землетрясение, террористический акт, наводнение, ураган, шторм, цунами, оползень, другие стихийные бедствия и катаклизмы, военные действия любого характера, забастовки, введение чрезвычайного или военного положения, режима повышенной готовности, эмбарго, изменения законодательства РФ, издание органами власти нормативных актов, повлекших невозможность надлежащего исполнения Сторонами своих обязательств и прочие обстоятельства, на которые стороны не могут повлиять и предотвратить.<br/>
                    3.4. О наступлении (и прекращении) указанных в пункте 3.3 настоящего Договора обстоятельств, Сторона, для которой возникла невозможность исполнения обязательств по настоящему Договору, обязана немедленно известить другую Сторону. Отсутствие уведомления или несвоевременное уведомление об указанных обстоятельствах лишает Стороны права ссылаться на эти обстоятельства и не освобождает от ответственности по настоящему Договору. Срок исполнения обязательств Сторонами по настоящему Договору отодвигается соразмерно времени, в течение которого будут действовать такие обстоятельства. Если обстоятельства будут продолжаться более 14 (четырнадцати) дней, каждая из Сторон будет иметь право отказаться от исполнения обязательств по настоящему договору. Наличие указанных обстоятельств подтверждается соответствующими решениями федеральных органов государственной власти, органов государственной власти субъектов РФ, органов местного самоуправления, принимаемыми ими в соответствии с федеральными законами.<br/>
                    3.5. При наступлении указанных обстоятельств Стороны вправе в судебном порядке потребовать расторжения договора.<br/>
                    3.6. Оператор не несет ответственности перед Пользователем за качество услуг, оказываемых Поставщиком услуг, а также за иные обязанности, которые возложены на Поставщика услуг в соответствии с действующим законодательством Российской Федерации.<br/>
                    3.7. Оператор не несет ответственности за случайные убытки Пользователя, убытки, определяемые особыми обстоятельствами дела, а также за убытки, присуждаемые в порядке наказания, или любые иные убытки, включая упущенную выгоду, потерю данных или ущерб деловой репутации, перебои в обслуживании, сбои в системе, за убытки вследствие причинения личного вреда, за телесные повреждения или моральный ущерб, возникающие в связи с условиями настоящего Договора; использованием или невозможностью использования Сайта или любых технических элементов, относящихся к нему; обменом информацией или встречами с лицами, с которыми Пользователь взаимодействует в рамках или в результате использования Сайта; бронированием, в том числе с предоставлением или использованием дополнительных услуг Поставщика услуг.<br/>
                    3.8. Ответственность, в том числе финансовую, перед Пользователем по Заказу несет соответствующий Поставщик услуг, данные о котором доступны Пользователю на Сайте. В случае возникновения любой конфликтной ситуации все требования и претензии предъявляются Пользователем к Поставщику услуг.
                  </p>
                  <p>
                    <b>4. Срок действия и изменение условий договора.</b><br/>
                    4.1. Условия Оферты действуют с даты указанной в графе «Дата вступления в силу» и до момента отзыва Оператором оферты.<br/>
                    4.2. Оператор оставляет за собой право в любое время и по своему усмотрению вносить изменения и дополнения в Договор путём изменения оферты, в связи с чем, Пользователь обязуется регулярно отслеживать изменения в Договоре, размещенном в разделе «Пользовательское соглашение». В случае внесения изменений в Договор-оферту, такие изменения вступают в силу с даты размещения измененного текста на сайте.<br/>
                    4.3. В случае, если Пользователь не согласен с внесенными изменениями он вправе расторгнуть Договор в порядке, предусмотренном в статье 5 настоящего Договора.
                  </p>
                  <p>
                    <b>5. Расторжение договора.</b><br/>
                    5.1. Договор может быть расторгнут:<br/>
                    5.1.1. В любое время по соглашению Сторон;<br/>
                    5.1.2. Оператором незамедлительно путем направления письменного уведомления (в том числе по электронной почте) Пользователю, в случае нарушения Пользователем условий настоящего Договора;<br/>
                    5.1.3. Любой из Сторон после направления другой Стороне письменного уведомления (в том числе по электронной почте) за 5 (пять) рабочих дней до предполагаемой даты расторжения.<br/>
                    5.2. Обязательства Сторон по Договору, которые в силу своего характера должны сохранять действие, включая обязательства, касающиеся конфиденциальности, порядка оплаты, использования информации, защиты персональных данных, но, не ограничиваясь вышеизложенным, сохраняют свою юридическую силу после расторжения Договора.<br/>
                    5.3. Расторжение Договора по любому основанию не освобождает Стороны от ответственности за нарушение условий Договора, возникшие в течение срока его действия.
                  </p>
                  <p>
                    <b>6. Интеллектуальная собственность</b><br/>
                    6.1. Вся текстовая информация и графические изображения, находящиеся на Сайте, являются собственностью Администрации сайта и/или его контрагентов.<br/>
                    6.2. Перепечатка, воспроизведение в любой форме, распространение, в том числе в переводе, любых материалов с Сайта возможны только с письменного разрешения Администрации сайта.<br/>
                    6.3. При использовании документов и связанных с ними графических изображений, расположенных на Сайте необходимо соблюдать следующие требования:<br/>
                    - указывать, что авторские права принадлежат Администрации сайта.<br/>
                    - при перепечатке материалов ссылаться на Сайт, как на источник публикации, и указывать иные источники информации, упомянутые в материале. Если материалы размещаются в Интернете, то необходимо сделать ссылку на Сайт.<br/>
                    6.4. Документы и связанные с ними графические изображения, расположенные на Сайте, могут быть использованы только в информационных, некоммерческих или личных целях.<br/>
                    6.5. Никакие документы и связанные с ними графические изображения, расположенные на Сайте, не должны изменяться никаким способом.<br/>
                    6.6. Никакие графические изображения, расположенные на Сайте, не должны использоваться отдельно от сопровождающего их текста.<br/>
                    6.7. Документы и связанные с ними графические изображения, расположенные на Сайте, могут включать неточности или орфографические ошибки. Изменение информации на Сайте производится периодически.
                  </p>
                  <p>
                    <b>7. Конфиденциальность и защита персональных данных</b><br/>
                    7.1. Зарегистрировавшись на Сайте и/или отметив пункт «Я даю согласие на использование и распространение моих персональных данных», Пользователь разрешает обработку своих персональных данных Оператору любым не запрещённым законом способом в том числе, но, не ограничиваясь: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение) c целью оказания услуг, предусмотренных настоящим Договором, улучшения качества услуг, информирования Пользователя о Заказах, в маркетинговых целях, для извещения Пользователя о новостях, для возможности участия в конкурсных программах и иных сервисах, предусмотренных для Пользователей Сайта, о мероприятиях, проводимых компанией и партнерами, а также для достижения целей, предусмотренных другими пунктами настоящего Договора<br/>
                    7.2. Предоставляя свои персональные данные при регистрации на Сайте, Пользователь соглашается на хранение, обработку своих персональных данных любым законно разрешенным способом, в объеме необходимом для выполнения условий настоящего Договора.<br/>
                    При регистрации на сайте Пользователь дает свое согласие на получение от Оператора рассылок рекламно-информационного характера.<br/>
                    7.3. Размещая на Сайте Заказ, Пользователь даёт согласие Оператору на передачу своих персональных данных Поставщикам услуг, агентам и третьим лицам, действующим на основании договора с Оператором для исполнения обязательств перед Пользователем.<br/>
                    7.3.1. Если Пользователь не желает, чтобы его персональные данные обрабатывались, то он должен обратиться к Оператору по п. 7.3.2. В таком случае вся полученная от Пользователя информация (в тот числе логин и пароль) удаляется из пользовательской базы Администрацией Сайта, с этого момента Пользователь теряет возможность размещения Заказов на Сайте.<br/>
                    7.3.2. Для прекращения распространения, передачи, предоставления, использования, хранения и обработку персональных данных Оператором Пользователем должно быть направлено заявление об отзыве согласия на их распространение на адрес электронной почты <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>, в таком заявлении в порядке должны быть указаны:<br/>
                    - Ф. И. О.;<br/>
                    - контакты (номер телефона, адрес электронной почты или почтовый адрес);<br/>
                    - перечень персональных данных, обработка которых должна быть прекращена.<br/>
                    В течение 3 (трех) рабочих дней с момента получения Оператором заявление об отзыве согласия на обработку персональных данных вся полученная от Пользователя информация (в тот числе логин и пароль) удаляется из пользовательской базы Сайта, с этого момента Пользователь теряет возможность размещения Заказов на Сайте.<br/>
                    7.4. Разглашение информации, полученной Оператором:<br/>
                    7.4.1. Оператор обязуется не разглашать полученную от Пользователя информацию. Не считается разглашением предоставление информации Поставщикам услуг, агентам и третьим лицам, действующим на основании договора с Оператором для исполнения обязательств перед Пользователем.<br/>
                    7.4.2. Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми требованиями закона.<br/>
                    7.5. Оператор получает информацию об IP-адресе посетителя Сайта. Данная информация не используется для установления личности посетителя.<br/>
                    7.6. Вне зависимости от факта совершения бронирования Пользователем при пользовании Сайтом, Оператор автоматически собирает следующие данные: IP-адрес Пользователя, дату и время пользования услугами Оператора, информацию об аппаратном и программном обеспечении компьютера Пользователя (например, об операционной системе, интернет-браузере, о версии программного обеспечения или приложений, а также о языковых настройках). Оператор также собирает информацию о кликах и показанных Пользователю страницах.<br/>
                    7.7. В случае использования Пользователем мобильного устройства, Оператор получает и собирает данные, идентифицирующие устройство Пользователя, а также информацию о его настройках и характеристиках, сбоях в работе приложения и других системных операциях. В случае оформления Пользователем Заказа с помощью такого устройства, система Оператора фиксирует, как Пользователь оформил Заказ (на каком сайте) и/или с каких сайтов перешел на Сайт.<br/>
                    7.8. Пользуясь Сайтом Оператора, Пользователь соглашается, что в случае, если Пользователь приступил (начал) к оформлению Заказа, но не завершил его онлайн, Оператор имеет право связаться с Пользователем и предложить завершить оформление Заказа.<br/>
                    7.9. Пользуясь Сайтом и услугами Оператора, Пользователь соглашается с тем, что Оператор вправе направить Пользователю опрос или попросить оставить отзыв о Сайте или Поставщике услуг.<br/>
                    7.10. Пользуясь Сайтом Оператора, Пользователь соглашается, что даже в случае отсутствия оформленных Заказов Пользователя, Оператор вправе направлять административные сообщения, которые могут содержать предупреждения, связанные с безопасностью.<br/>
                    7.11. Оператор не несет ответственности за сведения, предоставленные Пользователем на Сайте в общедоступной форме.
                  </p>
                  <p>
                    <b>8. Прочие условия</b><br/>
                    8.1. К отношениям между Пользователем и Оператором применяется законодательство Российской Федерации.<br/>
                    8.2. В случае возникновения у Пользователя вопросов он должен обратиться в Службу по работе с клиентами Оператора через электронную почту <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>. В том случае, если у Пользователя имеются претензии непосредственно к качеству работы Оператора, Пользователь подает письменную претензию в срок не более 20 (Двадцати) дней с момента возникновения причины спора. В течение 10 (Десяти) дней Оператор рассматривает претензию и направляет Пользователю обоснованный ответ. Все возникающее споры Стороны будут стараться решить путем переговоров, при недостижении соглашения спор будет передан на рассмотрение в судебный орган по месту нахождения Оператора.<br/>
                    8.3. Признание судом недействительности какого-либо положения настоящего Договора не влечет за собой недействительность остальных положений.
                  </p>
          
                  <p>
                    <b>Реквизиты Оператора:</b><br/>
                    ИП КАЗАНЦЕВ ВЛАДИСЛАВ ДМИТРИЕВИЧ<br/>
                    ИНН 245210737200, ОГРИП 318246800016960<br/>
                    <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a><br/>
                    <a href="tel:+79831402030">+7 (983) 140-20-30</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(UserAgreement)