import { makeAutoObservable } from "mobx";
import { XCategory } from "../models/store/category";
import {StoreCity} from "../models/store/city";
import {StoreOrder} from "../models/store/order";
import { XProduct } from "../models/store/product";
import {StoreShop} from "../models/store/shop";
import {StorageKeys} from "../utils/defaults";

export default class ShopStore {

  cart: any[] = []
  goods: any[] = []
  timezones: any[] = []

  products: XProduct[] = []
  homeProducts: XProduct[] = []
  totalProducts: number = 0

  categories: XCategory[] = []

  selectedProductId: number = 0
  selectedProduct: XProduct | null = null

  globalSearchText: string = ""

  isProductOpenned: boolean = false
  isProductVisible: boolean = false

  sort: string = "DESC"
  sortBy: string = "id"
  pageIndex: number = 0
  pageLimit: number = 20

  pageIndexA: number = 1
  pageLimitA: number = 50

  categoryFilter: string = ""

  leftPos: string = ""

  selectedCityName: string = ""
  selectedCity: StoreCity | null = null
  cities: StoreCity[] = []

  shops: StoreShop[] = []

  isCityVisible: boolean = false

  promocode: string = ""
  selectedPromocode: any | null = null

  cityQuestShown: boolean = false
  cityQuestVisible: boolean = false

  orders: any[] = []
  selectedOrder: any | null = null

  isBasketVisible: boolean = true
  isBasketPrevisible: boolean = true

  constructor() {
    makeAutoObservable(this)
  }

  setSort(val: string) {
    this.sort = val
  }

  setSortBy(val: string) {
    this.sortBy = val
  }

  setPageLimit(val: number) {
    this.pageLimit = val
  }

  setPageIndex(val: number) {
    this.pageIndex = val
  }

  setPageIndexA(val: number) {
    this.pageIndexA = val
  }

  hasActiveOrders() {
    return this.orders.findIndex(v => v.status < 7) !== -1
  }

  getActiveOrders() {
    return this.orders.filter(v => v.status < 7)
  }

  setIsBasketVisible(val: boolean) {
    this.isBasketVisible = val
  }

  setIsBasketPrevisible(val: boolean) {
    this.isBasketPrevisible = val
  }

  setOrders(val: StoreOrder[]) {
    this.orders = val
  }

  setSelectedOrder(val: StoreOrder | null) {
    this.selectedOrder = val
  }

  openCityQuest() {
    const already = localStorage.getItem(StorageKeys.CITY_QUEST_SHOWN)
    if (already != null) {
      return
    }
    this.cityQuestShown = true
    setTimeout(() => {
      this.cityQuestVisible = true
    }, 100)
  }

  closeCityQuest() {
    localStorage.setItem(StorageKeys.CITY_QUEST_SHOWN, "true")
    this.cityQuestVisible = false
    setTimeout(() => {
      this.cityQuestShown = false
    }, 300)
  }

  setCityQuestVisible(val: boolean) {
    this.cityQuestVisible = val
  }

  setCityQuestShown(val: boolean) {
    this.cityQuestShown = val
  }

  setSelectedPromocode(val: any) {
    this.selectedPromocode = val
  }

  setPromocode(value: string) {
    this.promocode = value
  }

  setIsCityVisible(val: boolean) {
    this.isCityVisible = val
  }

  setShops(val: any[]) {
    this.shops = val
  }

  setSelectedCityName(val: string) {
    this.selectedCityName = val
  }

  setSelectedCity(val: any) {
    this.selectedCity = val
  }

  getCityShops() {
    if (this.selectedCity != null) {
      return this.selectedCity.shops
    } else {
      return []
    }
  }

  setCities(val: any[]) {
    this.cities = val
  }

  setCategoryFilter(val: string) {
    this.categoryFilter = val
  }

  setLeftPos(val: string) {
    this.leftPos = val
  }

  setIsProductOpenned(val: boolean) {
    this.isProductOpenned = val
  }

  setIsProductVisible(val: boolean) {
    this.isProductVisible = val
  }

  getGlobalSearchText() {
    const exists = localStorage.getItem(StorageKeys.GLOBAL_SEARCH_TEXT)
    if (exists != null) {
      this.globalSearchText = exists
      return exists
    }
    return ""
  }

  setGlobalSearchText(val: string) {
    localStorage.setItem(StorageKeys.GLOBAL_SEARCH_TEXT, val)
    this.globalSearchText = val
  }

  getSortedGoods() {
    let gds = [...this.goods].sort((a: any, b: any) => {
      return a.id > b.id ? 1 : (a.id < b.id ? -1 : 0)
    })
    return gds
  }

  setTimezones(val: any[]) {
    this.timezones = val
  }

  setGoods(val: any[]) {
    this.goods = val
  }

  setSelectedProduct(val: XProduct | null) {
    this.selectedProduct = val
  }

  setSelectedProductId(val: number) {
    this.selectedProductId = val
  }

  setCategories(val: any[]) {
    this.categories = val
  }

  setTotalProducts(val: number) {
    this.totalProducts = val
  }

  setHomeProducts(val: XProduct[]) {
    this.homeProducts = val
  }

  setProducts(val: XProduct[]) {
    this.products = val
  }

  setCart(val: any) {
    this.cart = val
  }
}