import {API_URL, ControllerName} from "../utils/defaults";
import $api from "./http";

export class ApiService {

  constructor() {}

  static users = {
    checkAccount: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `auth/check`), dto)
    },
    signInWithCode: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `auth/signin`), dto)
    },
    signUpWithCode: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `auth/signup`), dto)
    },

    lostPassword: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `lost/sendcode`), dto)
    },

    signIn: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signin`), dto)
    },
    signInWithGoogle: async (jwtToken: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signin/google`), {token: jwtToken})
    },
    reauthWithGoogle: async (jwtToken: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `reauth/google`), {token: jwtToken});
    },
    updateAccount: async (dto: any, userId: number) => {
      return await $api.put(this.buildUrl(ControllerName.USERS, `account/${userId}`), dto)
    },
    updatePassword: async (id: number, dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `account/${id}/password`), dto)
    },
    deleteAccount: async (userId: number) => {
      return await $api.delete(this.buildUrl(ControllerName.USERS, `account/${userId}`))
    },
    getAccount: async () => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `account`))
    },

    getUsers: async (query: any) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `list${this.buildParams(query)}`))
    },
    getAccountById: async (userId: number) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `account/byid/${userId}`))
    }
  }

  static studios = {
    getMetros: async () => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `dadata/metros`))
    },

    getSections: async (sid: number) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `sections/${sid}`))
    },
    createSection: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `section`), dto)
    },
    updateSection: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `section/${id}`), dto)
    },
    deleteSection: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `section/${id}`))
    },

    getAllTickets: async (query: any) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `tickets`))
    },
    createStudioTicket: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `ticket`), dto)
    },
    updateStudioTicket: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `ticket/${id}`), dto)
    },
    deleteStudioTicket: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `ticket/${id}`))
    },

    getStats: async () => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `stats`))
    },
    getStudios: async (query: {
      city_id: number,
      page: number,
      limit: number,
      search: string,
      category: string,
      order: string,
      order_by: string,
      status: string,
      date: string,
      time: string,
      service: string,
      sort?: string,
      metro?: string,
      facils?: string,
      servs?: string
    }) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `list${this.buildParams(query)}`))
    },
    getStudioById: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `list/byid/${id}`))
    },
    getStudioStats: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `stats/${id}`))
    },
    getStudioByLink: async (link: string) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `list/bylink/${link}`))
    },
    getStudioBookings: async (id: number, date: string) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `list/bookings/${id}?date=${date}`))
    },
    getStudioPromos: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `list/promos/${id}`))
    },
    getStudioPromoResults: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `list/promos_res/${id}`))
    },
    getStudioServices: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `list/services/${id}`))
    },
    getCities: async () => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `cities`))
    },
    getCats: async () => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `cats`))
    },
    getServices: async (query: {
      search: string,
      limit: number,
      offset: number
    }) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `services${this.buildParams(query)}`))
    },
    getOrders: async (query: {
      page: number, limit: number, search: string, user_id: string, studio_id: string
    }) => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `orders_search${this.buildParams(query)}`))
    },
    getMyOrders: async () => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, `orders`))
    },
    createStudio: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `list`), dto)
    },
    updateStudio: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `list/${id}`), dto)
    },
    deleteStudio: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `list/${id}`))
    },
    createOrder: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `order`), dto)
    },
    updateOrder: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `order/${id}`), dto)
    },
    deleteOrder: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `order/${id}`))
    },
    createBooking: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `booking`), dto)
    },
    updateBooking: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `booking/${id}`), dto)
    },
    deleteBooking: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `booking/${id}`))
    },
    createCity: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `cities`), dto)
    },
    updateCity: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `cities/${id}`), dto)
    },
    deleteCity: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `cities/${id}`))
    },
    createCategory: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `cats`), dto)
    },
    updateCategory: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `cats/${id}`), dto)
    },
    deleteCategory: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `cats/${id}`))
    },
    getLikes: async () => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, 'likes'))
    },
    likeStudio: async (id: number) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `like/${id}`), {})
    },
    createPromocode: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `promos`), dto)
    },
    updatePromocode: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `promos/${id}`), dto)
    },
    deletePromocode: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `promos/${id}`))
    },
    activatePromocode: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `promocode`), dto)
    },
    createReview: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `review`), dto)
    },
    updateReview: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `review/${id}`), dto)
    },
    deleteReview: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `review/${id}`))
    },
    createService: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `service`), dto)
    },
    updateService: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `service/${id}`), dto)
    },
    deleteService: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `service/${id}`))
    },

    getServs: async () => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, 'servs'))
    },
    createServ: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `servs`), dto)
    },
    updateServ: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `servs/${id}`), dto)
    },
    deleteServ: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `servs/${id}`))
    },
    
    getFacils: async () => {
      return await $api.get(this.buildUrl(ControllerName.STUDIOS, 'facils'))
    },
    createFacil: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STUDIOS, `facils`), dto)
    },
    updateFacil: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STUDIOS, `facils/${id}`), dto)
    },
    deleteFacil: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STUDIOS, `facils/${id}`))
    }
  }

  static blog = {
    getPodcasts: async (query: any) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `podcasts${this.buildParams(query)}`))
    },
    getPodcastById: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `podcast/${id}`))
    },
    getPodcastByLink: async (link: string) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `podcasts/${link}`))
    },
    likePodcast: async (id: number) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `podcasts/${id}/like`))
    },
    createPodcast: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `podcasts`), dto)
    },
    updatePodcast: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BLOG, `podcasts/${id}`), dto)
    },
    deletePodcast: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `podcasts/${id}`))
    },

    getPosts: async (query: {
      search: string
      tag: string
      page: number
      limit: number
      order: string
      orderBy: string
    }) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `list${this.buildParams(query)}`))
    },
    getPostByLink: async (link_name: string) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `list/${link_name}/bylink`))
    },
    getPost: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `list/${id}`))
    },
    createPost: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list`), dto)
    },
    updatePost: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BLOG, `list/${id}`), dto)
    },
    deletePost: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `list/${id}`))
    },

    likePost: async (id: number) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list/${id}/like`), {})
    },
    lookPost: async (id: number) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list/${id}/look`), {})
    },
    commentPost: async (id: number, dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list/${id}/comment`), dto)
    },
    updateComment: async (id: number, cid: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BLOG, `list/${id}/comment/${cid}`), dto)
    },
    deleteComment: async (id: number, cid: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `list/${id}/comment/${cid}`))
    },
    addPostTag: async (id: number, tag_id: number) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list/${id}/tag/${tag_id}`), {})
    },
    deletePostTag: async (id: number, tag_id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `list/${id}/tag/${tag_id}`), {})
    },
    getTags: async () => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `tags`))
    },
    buildTags: async (type: string) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `tags/build/${type}`))
    },
    createTag: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `tags`), dto)
    },
    updateTag: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BLOG, `tags/${id}`), dto)
    },
    deleteTag: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `tags/${id}`))
    },
  }

  static core = {
    createMail: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.CORE, `mail`), dto)
    },
    getSettings: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `settings`))
    },
    updateSetting: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CORE, `settings/${id}`), dto)
    },
    getImageRes: async (filename: string) => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `image/${filename}`))
    }
  }

  static versions = {
    getVersions: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `versions`));
    },
    getLatestVersion: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `versions/latest`));
    },
    createVersion: async () => {},
    updateVersion: async () => {},
    deleteVersion: async () => {}
  }

  static buildUrl(
    controller: ControllerName,
    method: string
  ) {
    return `${API_URL}${controller}/${method}`
  }

  static buildParams(map: any) {
    let query = "";
    for (let key in map) {
      if (query == "") {
        query = `?${key}=${map[key]}`;
      } else {
        query += `&${key}=${map[key]}`;
      }
    }
    return query;
  }
}