import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import ImageSlider from "../components/ImageSlider"
import { Context } from ".."

import styles from "../styles/Acceptance.module.scss"
import useWindowDimensions from "../core/hooks/useWindowDimensions"

const AcceptPartner: FC = () => {

  const { store, adminStore } = useContext(Context)
  const { width } = useWindowDimensions()

  useEffect(() => {
    return () => {
      
    }
  }, [])

  return (
    <>
      <div className={styles.policy}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.wrapper}>
                <h1>ПАРТНЕРСКОЕ СОГЛАШЕНИЕ</h1>
                <h2>(договор публичной оферты)</h2>
                <h3>PODCASTBERY</h3>

                <p className={styles.addr}>Настоящий документ представляет собой предложение (публичную оферту) ИП Казанцева Владислава Дмитриевича (ИНН 245210737200, ОГРИП 318246800016960) (далее – «Оператор») заключить агентский договор на условиях, изложенных в настоящем документе (далее – «Оферта»).</p>

                <div className={styles.points}>
                  <p>
                    <b>ОСНОВНЫЕ ПОНЯТИЯ</b><br/> 
                    <b>Акцепт Оферты</b> – выполнение действий, предусмотренных п. 1.3 Оферты, что подразумевает безоговорочное принятие всех условий, предусмотренных Офертой и документами, которые являются неотъемлемой частью Оферты.<br/> 
                    <b>Договор</b> – агентский договор, заключённый между Оператором и Поставщиком услуг на условиях настоящей Оферты, путем ее Акцепта в соответствии с п.1.3.<br/> 
                    <b>Поставщик услуг (Поставщик)</b> – юридическое лицо, индивидуальный предприниматель или самозанятый, применяющий в своей деятельности специальный налоговый режим «Налог на профессиональный доход», имеющие законное право вступать в договорные отношения с Оператором, а также размещать объекты для бронирования на сайте <a href="https://podcastbery.ru/">https://podcastbery.ru/</a>, а также информацию о дополнительных платных и бесплатных услугах, осуществившее Акцепт настоящей Оферты.<br/> 
                    <b>Услуги Поставщика</b> – услуги, оказываемые Поставщиком, могут включать в себя предоставление в почасовую аренду Локаций, оборудования, а также иные дополнительные платные и бесплатные услуги Поставщика.<br/> 
                    <b>Личный кабинет</b> – интерактивное пространство, доступ к которому появляется при регистрации на сайте, позволяющее Сторонам обмениваться информацией и документами в электронном виде. Вход в личный кабинет осуществляется при помощи логина и пароля, известных только Поставщику услуг, при этом Поставщик услуг гарантирует, что данная информация используется исключительно им для осуществления взаимодействия с Оператором и третьими лицами на условиях настоящей Оферты.<br/> 
                    <b>Сайт (Сервис)</b> – информационный ресурс Оператора в интернете, расположенный по адресу <a href="https://podcastbery.ru/">https://podcastbery.ru/</a>, на котором осуществляется размещение Услуг Поставщика.<br/> 
                    <b>Локация</b> – пространство, информация о котором оформлена Поставщиком услуг в соответствии с требованиями Сайта, и размещена Оператором на Сайте для осуществления дальнейшего его бронирования Заказчиками.<br/> 
                    <b>Система бронирования</b> – информационная система, содержащая информацию, касающуюся наличия свободных часов в Локациях, а также дополнительных услугах, тарифах и правилах их применения, в том числе условиях предоставления предлагаемых услуг. Информация в Системе бронирования размещается на Сайте непосредственными Поставщиками данных услуг или их полномочными представителями. Информация в системе бронирования может в любой момент быть изменена или дополнена. Система бронирования также может быть размещена непосредственно на сайте Поставщика услуг.<br/> 
                    <b>Агентское вознаграждение</b> – установленное в соответствии с Тарифом, а также условиями настоящей Оферты вознаграждение, которое Поставщик услуг обязуется оплатить Оператору.<br/> 
                    <b>Заказчик</b> – лицо, непосредственно осуществляющее бронирование, которому Поставщик оказывает услуги по предоставлению Локации и/или иные услуги.<br/> 
                    <b>Заказ</b> – должным образом оформленный запрос Заказчика на получение услуг по бронированию Локаций Поставщика, а также дополнительных услуг, выбранных на Сайте.<br/> 
                    <b>Тариф</b> – определенный тарифный план Оператора, который зависит от срока предоставления партнерского доступа для размещения услуг и иных условий Сервиса. Тариф выставляется Оператором в личном кабинете Поставщика услуг и является неотъемлемой частью заключенного Договора. Информация о Тарифах размещена по адресу: <a href="https://podcastbery.ru/connect">https://podcastbery.ru/connect</a>.<br/>
                    <b>Правила Бронирования</b> – правила взаимодействия Заказчика с Поставщиком услуг для бронирования определенной услуги.<br/> 
                    <b>Автопродление</b> – автоматическое списание денежных средств по Тарифу на следующий срок по окончании предыдущего срока Тарифного плана или Пробного периода<br/> 
                    <b>Пробный период</b> – бесплатный пробный доступ к Сервису. Продолжительность Пробного периода определяется Оператором самостоятельно.
                  </p>
                  <p>
                    <b>1. ОБЩИЕ ПОЛОЖЕНИЯ</b><br/>
                    
                    1.1. Предоставляя информацию об Услугах Оператору для дальнейшего размещения на сайте, Поставщик услуг соглашается с условиями Договора публичной оферты (далее — Договор), изложенными ниже.<br/>
                    1.2. Настоящий Договор, а также приложения, относящиеся к нему, представленные на Сайте, являются публичной офертой в соответствии со ст. 435 и п. 2 ст. 437 ГК РФ. В соответствии с пунктом 3 статьи 438 ГК РФ, принятие содержащихся в настоящей оферте существенных условий, трактуемое в соответствии с пунктом 1 статьи 438 ГК РФ не иначе как акцепт, равносильно заключению агентского договора на изложенных ниже условиях.<br/>
                    1.3. Полным и безоговорочным принятием (акцептом) условий настоящей Оферты считается выполнение Поставщиком услуг следующих условий:<br/>
                    1.3.1. Заполнение регистрационной формы (анкеты) на Сайте и получение доступа (логин и пароль) к личному кабинету;<br/>
                    1.3.2. Осуществление оплаты услуг Оператора по выбранному Тарифу;<br/>
                    1.3.3. Предоставление информации об Услугах в объёме, указанном в регистрационной форме, в том числе о дополнительных услугах Поставщика и правилах пользования. <br/>
                    1.4. Поставщик услуг, принимая условия настоящей Оферты, соглашается с изложенными ниже условиями, а также с Пользовательским соглашением, Тарифами Оператора и дает согласие Оператору на получение, хранение, обработку и передачу своих персональных данных, в том числе из открытых источников для дальнейшего их размещения на Сайте Оператором. Поставщик услуг подтверждает свою право – и дееспособность, финансовую состоятельность, а также сознаёт ответственность за обязательства, возложенные на него в результате заключения настоящего Договора. Поставщик услуг подтверждает актуальность и достоверность своих личных данных, а также данных Локации, иных оказываемых Поставщиком Услуг, указанных при регистрации, и принимает на себя всю ответственность за их точность, полноту и достоверность. <br/>
                    1.5. К отношениям между Оператором и Поставщиком услуг применяются положения действующего законодательства Российской Федерации.
                  </p>
                  
                  <p>
                    <b>2. ПРЕДМЕТ ДОГОВОРА</b><br/>
                    
                    2.1. По настоящему Договору Оператор обязуется от имени и за счёт Поставщика услуг осуществить размещение на Сайте информации о предоставляемых Поставщиком услугах, а именно: о часах аренды Локации, доступном оборудовании или иной информации, а также об иных и (или) дополнительных платных и бесплатных услугах. <br/>
                    2.2. Используя средства автоматизации, предоставляемые Сайтом, Оператор обязуется осуществлять юридические и иные действия, направленные на совершение сделки по бронированию Локации, Услуг, предоставляемых Поставщиком, при этом приобретает права и становится обязанным по ним сам Поставщик услуг.<br/>
                    2.3. Оператор обязуется совершать Действия на следующих существенных условиях:<br/>
                    2.3.1. Поставщик услуг самостоятельно заполняет всю информацию о Локации, а также о предоставляемых услугах и ценах в соответствующие поля, в форме, размещенной в Личном кабинете на Сайте.<br/>
                    2.3.2. Поставщик услуг самостоятельно отслеживает поступившие на Сайт заявки и обеспечивает их выполнение.
                  </p>
                    
                  <p>
                    <b>3. РЕГИСТРАЦИЯ НА САЙТЕ</b><br/>
                    
                    3.1. Поставщик услуг регистрируется на Сайте, путём заполнения регистрационной анкеты и введения своих контактных данных. Регистрация производится на номер телефона, указанный Поставщиком услуг в соответствующем поле, который в дальнейшем будет использован в качестве логина к личному кабинету на Сайте. При этом пароль к личному кабинету на Сайте устанавливается Поставщиком услуг самостоятельно либо направляется Оператором на электронный адрес, указанный при регистрации. <br/>
                    3.2. Поставщик услуг обязуется не сообщать третьим лицам логин и пароль, указанные при регистрации. В случае возникновения у Поставщика услуг подозрений относительно безопасности его логина и пароля или возможности их несанкционированного использования третьими лицами, он обязуется незамедлительно уведомить об этом Оператора, направив электронное письмо по адресу <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>, либо через форму обратной связи, указанную на сайте.<br/>
                    3.3. Весь обмен информацией и документами по Договору производится Сторонами по усмотрению Оператора одним из следующих способов: в Личном кабинете Поставщика услуг, по электронной почте, почтой, с использованием курьеров.<br/>
                    Если иное прямо не установлено настоящей Офертой любое уведомление считается направленным надлежащим образом при размещении его в Личном кабинете Поставщика услуг и (или) по электронной почте Оператора <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>.<br/>
                    При обмене информацией через Личный кабинет Поставщика услуг ознакомление с данной информацией находится в пределах контроля и ответственности Поставщика услуг, который должен самостоятельно отслеживать появление и изменение информации, уведомлений и документов в Личном кабинете.
                  </p>
                    
                  <p>
                    <b>4. ВОЗНАГРАЖДЕНИЕ АГЕНТА И ПОРЯДОК РАСЧЕТОВ</b><br/>
                    
                    4.1. Поставщик услуг оплачивает услуги Оператора в соответствии с условиями выбранного им Тарифа.<br/> 
                    4.2. Для перехода Поставщика услуг на другой Тариф, Поставщику услуг необходимо направить Оператору письменный запрос на смену Тарифа по адресу электронной почты (e-mail), указанному в реквизитах Оператора в Договоре. Момент начала применения Поставщиком услуг другого Тарифа определяется моментом подтверждения Оператором возможности перехода на другой Тариф и фиксации Оператором изменённого Тарифа в Личном кабинете Поставщика услуг. Оператор оставляет за собой право изменять Тариф Поставщика услуг в одностороннем порядке в случае несоблюдения Поставщиком услуг критериев, определенных для соответствующего Тарифа.<br/>
                    В исключительных случаях, при наличии подписанного соглашения между Оператором и Поставщиком услуг Поставщику услуг выставляется индивидуальный Тариф, который может отличаться от Тарифов, размещенных на Сайте.<br/>
                    4.3. Датой выполнения Поставщиком обязательства по перечислению денежных средств Оператору считается дата списания денежных средств с расчётного счёта Поставщика в адрес Оператора.<br/>
                    4.4. Размещение рекламных материалов на Сайте и связанных с ним страницах в социальных сетях, оплачивается отдельно согласно согласованным Сторонами расценкам. <br/>
                    4.5. Стороны соглашаются, что выставление, направление, получение, подписание и обмен финансовыми документами (счёт, акты выполненных работ, акты сверок и пр.) между Сторонами осуществляется посредством обмена электронными документами через Личный кабинет или по электронной почте. Подготовка и предоставление указанных документов на бумажном носителе, осуществляется по отдельному запросу одной из Сторон.<br/>
                    Стороны согласовали следующий порядок сдачи-приёмки услуг по Договору:<br/>
                    4.5.1. Оператор в течение 10 (десяти) рабочих дней со дня окончания календарного месяца публикует отчёт за календарный месяц и размещает его в Личном кабинете Поставщика услуг на Сайте.<br/>
                    4.5.2. Поставщик услуг обязан в срок не позднее 15 (пятнадцати) рабочих дней со дня окончания календарного месяца осуществить сверку данных, указанных в отчёте. При несогласии с данными, приведенными Оператором в отчёте, Поставщик услуг обязан в течение срока, установленного настоящим пунктом Договора, обратиться к Оператору по электронной почте на электронный адрес <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a> для осуществления сверки. В случае неполучения Оператором возражений по отчёту по истечении 15 (пятнадцати) рабочих дней со дня окончания календарного месяца – услуги оператора считаются оказанными надлежащим образом и принятыми Поставщиком услуг в объёме согласно отчёту, направленным в адрес Поставщика услуг. Отчет, опубликованный в Личном кабинете Поставщика, имеет силу Акта передачи оказанных услуг Поставщику. <br/>
                    4.5.3. В случае если возражения Поставщика услуг относительно сумм, содержащихся в отчёте, будут признаны обоснованными, Оператор обязуется в срок не позднее 3 (Трех) рабочих дней от даты получения Оператором возражений разместить в личном кабинете Поставщика услуг уточнённый отчёт. Возражения к уточнённому отчету Поставщик услуг вправе заявить не позднее первого рабочего дня, следующего за днем его получения, в противном случае уточнённый отчет считается принятым Поставщиком услуг без замечаний, а соответствующие услуги Оператора – оказанными в полном объеме и надлежащим образом.<br/>
                    4.5.4. Стороны договорились, что датой получения Поставщиком услуг отчёта или уточнённого отчёта считается дата размещения Оператором указанных документов в личном кабинете Поставщика услуг на Сайте, а датой получения Оператором возражений Поставщика услуг относительно сумм, содержащихся в отчёте – дата отправки соответствующих документов Поставщиком услуг по электронной почте на электронный адрес <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>.<br/>
                    4.6. Пробный период и автопродление:<br/>
                    4.6.1. Оператор вправе предоставить новым Поставщикам услуг Пробный период использования Сервиса. Пробный период исчисляется с даты верификации банковской карты Поставщика в Сервисе, т.е. получения Оператором подтверждения платежей в соответствии с предоставленными данными.<br/>
                    Для целей Соглашения новым считается Поставщик, использующий новый логин/номер телефона. <br/>
                    4.6.2. Продолжительность Пробного периода определяется Оператором самостоятельно. Точный срок Пробного периода указывается на станице регистрации Поставщика услуг. <br/>
                    4.6.3. При предоставлении Пробного периода Оператор самостоятельно определяет объем доступного Поставщику функционала Сервиса и период его доступности.<br/>
                    4.6.4. Оператор в рамках проведения маркетинговых мероприятий (рекламных акций и т.д.) вправе предоставить Поставщику услуг на время проведения таких мероприятий Пробный период к Сервису. При этом Оператор самостоятельно определяет объем функционала Сервиса, доступ к которому предоставляется в рамках указанных мероприятий, а также иные условия получения такого доступа Поставщиком (например, Регистрация в Сервисе или совершение иных действий Поставщиком).<br/>
                    4.6.5. При получении и оформлении Пробного периода доступа к Сервису автоматически подключается Автопродление.<br/>
                    4.6.6. Поставщик услуг вправе отключить Автопродление в любое время через Личный кабинет. При отключении Автопродления возможно сокращение срока действия Пробного периода.<br/>
                    4.6.7. Автопродление по Тарифу осуществляется посредством автоматического списания денежных средств с банковского счета Поставщика услуг согласно правилам и условиям банка и/или платежной системы, при условии наличия денежных средств на таком счете.<br/>
                    4.6.8. Поставщик услуг соглашается на договорное списание денежных средств его обслуживающим банком с его банковского счета по окончании пробного периода при оформлении Тарифа.<br/>
                    4.6.9. В случае отсутствия средств на банковском счете Поставщика на момент осуществления автоматического списания, автоматическое списание может быть повторено до момента успешного списания средств в срок до 60 (шестидесяти) календарных дней с момента первой попытки такого автоматического списания.
                  </p>
                    
                  <p>
                    <b>5. ПРАВА И ОБЯЗАННОСТИ СТОРОН</b><br/>
                    
                    5.1. Поставщик услуг обязуется:<br/>
                    5.1.1. размещать актуальную и достоверную информацию в соответствующих полях в Личном кабинете на Сайте, соблюдая правила размещения информации и изображений и условия пользовательского соглашения, а именно:<br/>
                    5.1.1.1. не размещать недостоверную информацию;<br/>
                    5.1.1.2. не размещать изображения, содержащие эротику;<br/>
                    5.1.1.3. не размещать изображения, не отражающие индивидуальность помещения Поставщика услуг, которые могут ввести пользователей в заблуждение, т. е. такие изображения, которые не дают пользователю представления о самой Локации, либо об иных оказываемых им услугах;<br/>
                    5.1.1.4. не размещать в описании Услуг гиперссылки;<br/>
                    5.1.2. самостоятельно и своевременно обновлять информацию и цены на свои Услуги в Личном кабинете на Сайте, при этом цены при бронировании Услуг через Сайт не должны превышать цены при бронировании непосредственно через Поставщика услуг или третьих лиц;<br/>
                    5.1.3. самостоятельно отслеживать поступающие в автоматизированном режиме заказы от Заказчиков и обеспечивать их своевременное и надлежащее выполнение;<br/>
                    5.1.4. предоставлять услуги Заказчикам, привлечённым Оператором и осуществившим заказ услуг Поставщика услуг, в соответствии с Правилами Сайта.<br/>
                    5.1.5. принять от Оператора все исполненное им в соответствии с условиями настоящего Договора;<br/>
                    5.1.6. систематически осуществлять проверку Личного кабинета на Сайте, адреса электронной почты, указанного при регистрации на сайте, а также указанных реквизитов. В случае изменения банковских реквизитов Поставщик услуг обязуется незамедлительно уведомить об этом Оператора;<br/>
                    5.1.7. при необходимости вносить актуальные изменения в Описание и правила бронирования, связанные с особенностью оказываемых Услуг.<br/>
                    5.1.8. не использовать роботы, программы-обходчики, боты и иные автоматизированные средства или процессы для сбора данных или другого контента, а также для доступа к ним и совершения иных действий на Сайте вне зависимости от целей;<br/>
                    5.1.9. не предпринимать какие-либо действия, которые наносят ущерб и/или негативно влияют на работоспособность Сайта, а также могут нанести ущерб или негативно повлиять на надлежащее функционирование Сайта.

                    5.2. Поставщик услуг имеет право:<br/>
                    5.2.1. получать оперативную информацию о поступающих заказах и бронированиях;<br/>
                    5.2.2. получать консультации по работе сервиса в рабочее время в будние дни;<br/>
                    5.2.3. получать дополнительные услуги согласно расценкам, установленным Оператором;<br/><br/>

                    5.3. Загружая фотографии/изображения на Сайт, Поставщик услуг подтверждает и гарантирует, что является обладателем авторских прав на эти фотографии/изображения и даёт свое согласие на использование Оператором загруженных фотографий/изображений на Сайте, в рекламных материалах и публикациях, размещённых онлайн и офлайн, а также любым другим образом на усмотрение Оператора.<br/>
                    Поставщик услуг предоставляет Оператору неисключительное, действующее во всём мире, безотзывное, безусловное и бессрочное право и разрешение на использование, воспроизведение, отображение, копирование, распространение, лицензирование, передачу и размещение фотографий/изображений любым способом по усмотрению Оператора.<br/>
                    Загружая фотографии/изображения, Поставщик услуг принимает на себя полную ответственность за достоверность, действительность и право на использование всех фотографий/изображений, за претензии юридического характера со стороны третьих лиц, в связи с публикациями и использованием Оператором этих фотографий/изображений.<br/>
                    Поставщик услуг гарантирует, что фотографии/изображения не содержат вирусы, троянские программы или файлы, заражённые вирусами, а также не содержат никаких порнографических, незаконных, непристойных, оскорбительных, неприемлемых или неуместных материалов и не нарушают права третьих лиц, в том числе право интеллектуальной собственности, авторские права или право на неприкосновенность частной жизни.<br/>
                    5.4. При наличии технической возможности Сайта в актуальной версии, при соответствующем Тарифе Оператор предоставляет возможность Поставщику услуг использовать внутреннюю систему управления взаимоотношениями с Заказчиками, направленную на автоматизацию стратегий взаимодействия с Заказчиками, в частности, для повышения уровня продаж, оптимизации маркетинга и улучшения обслуживания клиентов путём сохранения информации о Заказчиках и истории взаимоотношений с ними, установления и улучшения бизнес-процессов и последующего анализа результатов.<br/><br/>

                    5.5. Оператор обязуется:<br/>
                    5.5.1. размещать в открытом доступе на Сайте информацию, предоставленную Поставщиком услуг в Личном кабинете на Сайте;<br/>
                    5.5.2. обеспечить доступ Поставщика услуг к информации, о текущем выполнении обязательств Оператора по отношению к Поставщику услуг;<br/>
                    5.5.3. принимать заявки на устранение ошибок и устранять их в разумные сроки;<br/>
                    5.5.4. за свой счёт поддерживать работу Сайта;<br/>
                    5.5.5. осуществлять проверку адреса электронной почты <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>.<br/><br/>

                    5.6. Оператор имеет право:<br/>
                    5.6.1. посещать Локацию Поставщика услуг в рабочее время и проверять соответствие заявленных характеристик фактически существующим;<br/>
                    5.6.2. самостоятельно изменять или заблокировать информацию, размещённую на Сайте, которая не соответствует правилам размещения информации;<br/>
                    5.6.3. приостановить доступ к Личному кабинету, в случае обнаружения подозрительной активности, в частности при обнаружении попыток целенаправленного использования сервиса против интересов Оператора или иных третьих лиц, уведомив об этом контактное лицо со стороны Поставщика услуг;<br/>
                    5.6.4. менять условия Тарифа в одностороннем порядке;<br/>
                    5.6.5. проводить поощрительные мероприятия, направленные на повышение активности Поставщика услуг;<br/>
                    5.6.6. удалять информацию, фотографии/изображения, которые не соответствуют п.п. 5.1.1, 5.3 Договора, в любое время и без предварительного уведомления Поставщика услуг.
                  </p>
      
                  <p>
                    <b>6. ПОРЯДОК ПРЕДОСТАВЛЕНИЯ УСЛУГИ</b><br/>
                    
                    6.1. Оператор после регистрации Поставщика услуг предоставляет ему доступ к Личному кабинету на Сайте, а также возможность размещать информацию о предоставляемой Услуге.<br/>
                    6.2. Поставщик услуг размещает информацию об Услуге в Личном кабинете, в специально предназначенных для этого полях, в соответствии с правилами размещения материалов на Сайте.<br/>
                    6.3. Оператор обеспечивает размещение информации о Локации, а также иных услугах Поставщика услуг, в открытом доступе на Сайте.<br/>
                    6.4. Оператор обеспечивает обработку заявок на услуги Поставщика услуг и уведомляет его информационным сообщением непосредственно в Личном кабинете, а также по адресу электронной почты, указанной Поставщиком услуг в соответствующем поле в Личном кабинете на Сайте. Поставщик услуг имеет возможность самостоятельно настроить методы уведомления об осуществленных операциях.<br/>
                    6.5. Поставщик принимает оплату от Заказчиков за услуги самостоятельно, Оператор не оказывает услуги платежного агрегатора и не несет ответственность за исполнение Заказчиком своих обязательств по оплате Услуг Поставщика.<br/>
                    6.6. Дополнительные услуги предоставляются Оператором по запросу Поставщика услуг и оплачиваются Поставщиком дополнительно.
                  </p>
        
                  <p>
                    <b>7. ОТВЕТСТВЕННОСТЬ СТОРОН</b><br/>
                    
                    7.1. В случае непредставления Поставщиком услуг Заказчику Локации в забронированные часы (в том числе при наложении броней по вине Поставщика услуг при несвоевременном/некорректном внесении данных о бронях), Поставщик выплачивает Оператору штраф в размере 1 000 (одна тысяча) рублей за каждый обнаруженный случай. Оператор также вправе удержать сумму штрафа от полной суммы внесённой Поставщиком предоплаты по Тарифу, уменьшив сроки оказываемой услуги по Тарифу соразмерно размеру удержанного штрафа. Оператор вправе заблокировать аккаунт Поставщика услуг до момента погашения выставленного штрафа.<br/>
                    7.2. В случае нарушения Поставщиком услуг правил размещения информации на Сайте, Оператор оставляет за собой право приостановить доступ к Сайту, до устранения нарушений.<br/>
                    7.3. В случае нарушения Поставщиком услуг условия о цене, указанного в п. 5.1.2 Договора, Поставщик услуг уплачивает Оператору штраф:<br/>
                    - в размере 1 000 (одна тысяча) рублей, в случае обнаружения Оператором факта нарушения.<br/>
                    Оператор вправе удерживать сумму указанных штрафов от полной суммы внесённой Поставщиком предоплаты по Тарифу, уменьшив сроки оказываемой услуги по Тарифу соразмерно размеру удержанного штрафа. Оператор вправе заблокировать аккаунт Поставщика услуг до момента погашения выставленного штрафа.<br/>
                    7.4. В случае отмены Поставщиком услуг Заказа, поступившего через Сайт Оператора, без реального намерения аннулировать такой Заказ, Оператор оставляет за собой право взыскать с Поставщика услуг фактически понесённые Оператором расходы, а также штраф в размере 2 000 (две тысячи) рублей. <br/>
                    7.5. Оператор несёт перед Поставщиком услуг ответственность в случае полной или частичной неработоспособности системы или ее компонентов в течение какого-либо времени при отсутствии возможности доступа Поставщика услуг к Личному кабинету за сорванные в связи с такими событиями бронированиями в размере, не превышающем агентское вознаграждение за сорванные бронирования по вине Оператора, при наличии причинно-следственной связи.<br/>
                    7.6. Штрафные санкции, предусмотренные п. 7.5 настоящего Договора, подлежат применению в случае направления Стороной, чьи права нарушены, другой Стороне письменного требования, подписанного уполномоченным представителем такой Стороны. При отсутствии надлежащим образом оформленного письменного требования штрафные санкции не начисляются и не уплачиваются. Оплата штрафных санкций не освобождает от выполнения обязательств по настоящему Договору.<br/>
                    7.7. Поставщик услуг несёт ответственность перед Пользователем по заключённой с ним сделке в том числе, но, не ограничиваясь: за ненадлежащую информацию об услуге; за вред, причинённый Пользователю вследствие недостатков услуги; за убытки, причинённые Пользователю; за неисполнение или ненадлежащее исполнение обязательств; за нарушение прав Пользователя, произошедшее по вине Поставщика услуг или лиц его представляющих.<br/>
                    7.8. Ответственность Сторон в случаях, не предусмотренных настоящим Договором, определяется в соответствии с действующим законодательством РФ.
                  </p>
        
                  <p>
                    <b>8. Рейтинг и продвижение, отзывы Заказчиком</b><br/>
                    
                    8.1. Рейтинг Услуг формируется автоматически на основе отзывов Заказчиков. <br/>
                    8.2. Поставщик не вправе предъявлять Оператору претензии относительно своего Рейтинга и/или Рейтинга своих Услуг/Локаций. <br/>
                    8.3. Оператор вправе публиковать на Сервисе любые отзывы Заказчиков. <br/>
                    8.4. Оператор не осуществляет модерацию отзывов Заказчиков, за исключением случаев, если подобные отзывы содержат нецензурную лексику или упоминания личных имён или в них содержится информация, нарушающая Законодательство. <br/>
                    8.5. Поставщик вправе обращаться к Оператору с просьбой об удалении отзыва Заказчика в случае, если отзыв не соответствует требованиям Договора, нарушает права третьих лиц или нормы морали и нравственности. <br/>
                    8.6. При любых обстоятельствах Оператор не несёт ответственности за содержание отзывов Заказчиков и последствия их опубликования.<br/>
                    8.7. Поставщик не вправе публиковать, рекламировать, продвигать, копировать, ссылаться, приобретать или иным образом использовать отзывы Клиентов без предварительного письменного согласия Оператора.<br/>
                    8.8. Продвижение в строке поиска. Позиция Услуг в строке поиска на Сервисе зависит от следующих факторов: <br/>
                    8.8.1. рейтинг Услуг; <br/>
                    8.8.2. цена Услуг; <br/>
                    8.8.3. соотношение числа Бронирований к числу посещений Страницы Услуг; <br/>
                    8.8.4. количество Бронирований Услуг; <br/>
                    8.8.5. коэффициент отмен Бронирований; <br/>
                    8.8.6. степень наполненности Страницы Услуг; <br/>
                    8.8.7. наличие скидок и специальных предложений для Заказчика; <br/>
                    8.8.8. периодичность обновления Контента; <br/>
                    8.8.9. количество и тип жалоб Заказчиков на Услуги и/или Поставщика; <br/>
                    8.9. У Поставщика имеется возможность влиять на продвижение Услуг в строке поиска путём постоянного улучшения факторов, указанных в п. 8.8 Договора. 
                  </p>
        
                  <p>
                    <b>9. ФОРС-МАЖОР</b><br/>
                  
                    9.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, возникших после заключения настоящего Договора в результате событий чрезвычайного характера, которые стороны не могли предвидеть и предотвратить разумными мерами. К обстоятельствам непреодолимой силы относятся события, на которые пострадавшая Сторона не может оказать влияния и за возникновение которых не несёт ответственности, в т. ч. стихийные бедствия, землетрясения, наводнения, ураганы, технологические катастрофы, эпидемии, военные действия, забастовки, правительственные меры, ограничивающие исполнение обязательств.<br/>
                    9.2. При наступлении действия обстоятельств непреодолимой силы, исполнение Договора может быть приостановлено на время их действия и ликвидации, без каких-либо санкций по отношению к пострадавшей Стороне. В случае, если обстоятельства непреодолимой силы действуют более 3 (трёх) месяцев любая из Сторон вправе отказаться от дальнейшего исполнения Договор письменно уведомив об этом другую Сторону.<br/>
                    9.3. Сторона, которая не исполняет свое обязательство в связи с действием обстоятельств непреодолимой силы, обязана письменно известить другую Сторону о препятствии и его влиянии на исполнение обязательств по Договору в течение 7 (семи) дней с момента их возникновения.<br/>
                    9.4. Сторона, ссылающаяся на обстоятельства непреодолимой силы, не позднее 15 (пятнадцати) дней с момента их наступления, направляет другой стороне по электронной почте и (или) почтовым отправлением документальное подтверждение действия таких обстоятельств.<br/>
                    9.5. Свидетельство или иной документ, выданный соответствующей Торгово-Промышленной палатой или иным компетентным органом, является достаточным подтверждением наличия и продолжительности действия обстоятельств непреодолимой силы.
                  </p>
        
                  <p>
                    <b>10. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</b><br/>
                  
                    10.1. Информация, не предназначенная для публикации на Сайте и полученная в рамках настоящего Договора Сторонами, признаётся конфиденциальной. Стороны обязуются не раскрывать такую информацию третьим лицам, а также не использовать её для иных целей, кроме как для выполнения своих обязательств по данному Договору, без предварительного письменного согласия другой Стороны.<br/>
                    10.2. Все товарные знаки и символы, равно как и иные объекты исключительных прав (интеллектуальная собственность, включая авторские и смежные права) Поставщика услуг являются и остаются его собственностью. Настоящий Договор и его исполнение не влекут за собой передачу Оператору каких-либо прав Поставщика услуг.
                  </p>
        
                  <p>
                    <b>11. СРОК ДЕЙСТВИЯ И ИЗМЕНЕНИЕ УСЛОВИЙ ДОГОВОРА-ОФЕРТЫ</b><br/>
                  
                    11.1. Условия Договора действуют с даты, указанной в графе «Дата вступления в силу» в соответствующей редакции и до момента отзыва Оператором Оферты.<br/>
                    11.2. Оператор оставляет за собой право в любое время и по своему усмотрению вносить изменения и дополнения в настоящий Договор и документы, составляющие его неотъемлемую часть, в связи с чем, Поставщик услуг обязуется регулярно отслеживать изменения в Договоре, размещенном на Сайте, а также изменения в Тарифах. В случае внесения изменений в Договор-оферту и в документы, составляющие его неотъемлемую часть, такие изменения вступают в силу с даты размещения изменённого текста на Сайте.<br/>
                    11.3. В случае, если Поставщик услуг не согласен с внесёнными изменениями, он вправе расторгнуть Договор в порядке, предусмотренном в статье «РАСТОРЖЕНИЕ ДОГОВОРА». В случае, если Поставщик услуг продолжает пользоваться услугами, предоставляемыми Оператором, внесённые изменения и дополнения в Договор считаются безоговорочно принятыми Поставщиком услуг.
                  </p>
        
                  <p>
                    <b>12. РАСТОРЖЕНИЕ ДОГОВОРА</b><br/>
                  
                    12.1. Договор может быть расторгнут:<br/>
                    12.1.1. В любое время по соглашению Сторон;<br/>
                    12.1.2. Оператором незамедлительно путём направления письменного уведомления (в том числе по электронной почте) Поставщику услуг, в случае нарушения Поставщиком услуг условий настоящего Договора;<br/>
                    12.1.3. Любая из Сторон после направления другой Стороне письменного уведомления (в том числе по электронной почте) за 5 (пять) рабочих дней до предполагаемой даты расторжения.<br/>
                    12.2. Обязательства Сторон по Договору, которые в силу своего характера должны сохранять действие (включая обязательства, касающиеся конфиденциальности, порядка оплаты, использования информации, защиты персональных данных, но, не ограничиваясь вышеизложенным) сохраняют свою юридическую силу после расторжения Договора.<br/>
                    12.3. Расторжение Договора по любому основанию не освобождает Стороны от ответственности за нарушение условий Договора, возникших в течение срока его действия. Расторжение Договора не освобождает Стороны от обязательств, возникших до прекращения его действия.
                  </p>
        
                  <p>
                    <b>13. ПРОЧИЕ УСЛОВИЯ</b><br/>
                  
                    13.1. Все споры или разногласия, возникающие между Сторонами по настоящему Договору или в связи с ним, разрешаются путём переговоров. В случае невозможности разрешения разногласий путём переговоров в течение 10 (десяти) рабочих дней со дня направления приглашения для участия в таких переговорах, разногласия подлежат разрешению в суде по месту нахождения Оператора.<br/>
                    13.2. Признание судом недействительности какого-либо положения настоящего Договора не влечёт за собой недействительность остальных положений.<br/>
                    13.3. Поставщик услуг согласен с тем, что с момента акцепта настоящей Оферты все ранее имеющиеся между сторонами договорённости прекращают своё действие, заключённые ранее договоры считаются расторгнутыми по соглашению Сторон. Дальнейшее взаимодействие Сторон происходит на условиях настоящего Договора.
                  </p>
          
                  <p>
                    <b>Реквизиты Оператора:</b><br/>
                    ИП КАЗАНЦЕВ ВЛАДИСЛАВ ДМИТРИЕВИЧ<br/>
                    ИНН 245210737200, ОГРИП 318246800016960<br/>
                    <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a><br/>
                    <a href="tel:+79831402030">+7 (983) 140-20-30</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(AcceptPartner)