import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import ImageSlider from "../components/ImageSlider"
import { Context } from ".."

import styles from "../styles/Policy.module.scss"
import useWindowDimensions from "../core/hooks/useWindowDimensions"

const Policy: FC = () => {

  const { store, adminStore } = useContext(Context)
  const { width } = useWindowDimensions()

  useEffect(() => {
    return () => {
      
    }
  }, [])

  return (
    <>
      <div className={styles.policy}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.wrapper}>
                <h1>ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                {/* <h2>Политика в отношении обработки персональных данных</h2> */}

                <p className={styles.addr}>Настоящая Политика обработки персональных данных является неотъемлемой частью Публичной оферты (далее – Пользовательское соглашение), размещенной на сайте в сети Интернет по адресу: <a href="https://podcastbery.ru">https://podcastbery.ru</a> (далее – Сайт). Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования сервисов.</p>

                <div className={styles.points}>
                  <p>
                    <b>ОСНОВНЫЕ ПОНЯТИЯ</b><br/>
                    <b>Автоматизированная обработка персональных данных</b> - обработка персональных данных с помощью средств вычислительной техники.<br/>
                    <b>Блокирование персональных данных</b> - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).<br/>
                    <b>Заказ</b> - должным образом оформленный запрос Пользователя на получение услуг по бронированию Локаций, а также дополнительных услуг, выбранных на Сайте.<br/>
                    <b>Обезличивание персональных данных</b> - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.<br/>
                    <b>Обработка персональных данных</b> - любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя в том числе:<br/>
                    - сбор;<br/>
                    - запись;<br/>
                    - систематизацию;<br/>
                    - накопление;<br/>
                    - хранение;<br/>
                    - уточнение (обновление, изменение);<br/>
                    - извлечение;<br/>
                    - использование;<br/>
                    - передачу (распространение, предоставление, доступ);<br/>
                    - обезличивание;<br/>
                    - блокирование;<br/>
                    - удаление;<br/>
                    - уничтожение.<br/><br/>

                    <b>Оператор персональных данных (оператор)</b> - ИП Казанцев Владислав Дмитриевич (ИНН 245210737200, ОГРИП 318246800016960), самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.<br/>
                    <b>Персональные данные</b> - 1) любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных), которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учетной записи) или в процессе использования Сайта, включая персональные данные Пользователя. Обязательная для использования Сайта информация помечена специальным образом; 2) данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация.<br/>
                    <b>Пользователь</b> - дееспособное физическое лицо, достигшее 18 лет, имеющее законное право вступать в договорные отношения с Оператором, в том числе размещать заказ или бронировать услуги на Сайте.<br/>
                    <b>Предоставление персональных данных</b> - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.<br/>
                    <b>Распространение персональных данных</b> - действия, направленные на раскрытие персональных данных неопределенному кругу лиц.<br/>
                    <b>Сайт</b> - информационный ресурс Оператора в интернете, расположенный по адресу: <a href="https://podcastbery.ru/">https://podcastbery.ru/</a>.<br/>
                    <b>Уничтожение персональных данных</b> - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.<br/>
                  </p>
                  <p>
                    <b>1. Общие положения</b><br/>
                    1.1. Настоящая Политика применяется только к Сайту. Сайт не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.<br/>
                    1.2. Во исполнение требований ч. 2 ст. 18.1 Федерального закона «О персональных данных» от 27.07.2006 N152-ФЗ (далее – Закон о персональных данных) настоящая Политика публикуется в свободном доступе в информационно-телекоммуникационной сети Интернет на сайте Оператора.
                  </p>
                  <p>
                    <b>2. Персональная информация, собираемая Оператором. Цели обработки персональной информации</b><br/>
                    2.1. Оператор собирает и хранит персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.<br/>
                    2.2. Зарегистрировавшись на Сайте и/или отметив пункт «Я даю согласие на использование и распространение моих персональных данных», и/или разместив Заказ на Сайте, в том числе путём нажатия кнопки «Перейти к оплате», Пользователь разрешает обработку своих персональных данных Оператору любым не запрещённым законом способом в том числе, но, не ограничиваясь: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение) в целях, предусмотренных п.2.4 настоящей Политики.<br/>
                    2.3. Предоставляя свои персональные данные при регистрации на Сайте и/или размещении Заказа, Пользователь соглашается на хранение, обработку, передачу своих персональных данных любым законно разрешенным способом, в объеме необходимом для выполнения условий Пользовательского соглашения.<br/>
                    2.4. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:<br/>
                    2.4.1. Идентификации Пользователя, зарегистрированного на Сайте для оказания услуг, предусмотренных Пользовательским соглашением.<br/>
                    2.4.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.<br/>
                    2.4.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, информирование Пользователя о Заказах, обработку запросов и заявок от Пользователя.<br/>
                    2.4.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.<br/>
                    2.4.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.<br/>
                    2.4.6. Создания учетной записи на Сайте.<br/>
                    2.4.7. Уведомления Пользователя Сайта о новостях, для возможности участия в конкурсных программах и иных сервисах, предусмотренных для Пользователей Сайта, о мероприятиях, проводимых компанией и партнерами.<br/>
                    2.4.8. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта, в том числе для улучшения качества услуг.<br/>
                    2.4.9. В маркетинговых целях.<br/>
                    2.4.10. Осуществления рекламной деятельности с согласия Пользователя.<br/>
                    2.4.11. А также для достижения целей, предусмотренных пунктами Пользовательского соглашения.<br/>
                    2.5. Если Пользователь не желает, чтобы его персональные данные обрабатывались, то он должен обратиться к Оператору через электронную почту <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>. В таком случае вся полученная от Пользователя информация (в тот числе логин и пароль) удаляется из пользовательской базы Оператором Сайта в сроки, указанные в п. 2.5.1 настоящей Политики, с этого момента Пользователь также теряет возможность размещения Заказов на Сайте.<br/>
                    2.5.1. Для прекращения распространения, передачи, предоставления, использования, хранения и обработки персональных данных Оператором Пользователем должно быть направлено заявление об отзыве согласия на их распространение Оператору через электронную почту <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>, в таком заявлении в порядке должны быть указаны:<br/>
                    -	Ф.И.О.;<br/>
                    -	контакты (номер телефона, адрес электронной почты или почтовый адрес);<br/>
                    - перечень персональных данных, обработка которых должна быть прекращена.<br/><br/>

                    В течение 10 (десяти) рабочих дней с момента получения Оператором заявление об отзыве согласия на обработку персональных данных вся полученная от Пользователя информация (в тот числе логин и пароль) удаляется из пользовательской базы Сайта, с этого момента Пользователь теряет возможность размещения Заказов на Сайте.<br/>
                    2.6. Оператор получает информацию об IP-адресе посетителя Сайта. Данная информация не используется для установления личности посетителя.<br/>
                    2.7. Вне зависимости от факта совершения бронирования Пользователем при пользовании Сайтом, Оператор автоматически собирает следующие данные: IP-адрес Пользователя, дату и время пользования услугами Оператора, информацию об аппаратном и программном обеспечении компьютера Пользователя (например, об операционной системе, интернет-браузере, о версии программного обеспечения или приложений, а также о языковых настройках). Оператор также собирает информацию о кликах и показанных Пользователю страницах.<br/>
                    2.8. В случае использования Пользователем мобильного устройства, Оператор получает и собирает данные, идентифицирующие устройство Пользователя, а также информацию о его настройках и характеристиках, сбоях в работе приложения и других системных операциях. В случае оформления Пользователем Заказа с помощью такого устройства, система Оператора фиксирует, как Пользователь оформил Заказ (на каком сайте) и/или с каких сайтов перешел на Сайт.<br/>
                    2.9. Пользуясь Сайтом Оператора, Пользователь соглашается, что в случае, если Пользователь приступил (начал) к оформлению Заказа, но не завершил его онлайн, Оператор имеет право связаться с Пользователем и предложить завершить оформление Заказа.<br/>
                    2.10. Пользуясь Сайтом и услугами Оператора, Пользователь соглашается с тем, что Оператор вправе направить Пользователю опрос или попросить оставить отзыв о Сайте или Поставщике услуг.<br/>
                    2.11. Пользуясь Сайтом Оператора, Пользователь соглашается, что даже в случае отсутствия оформленных Заказов Пользователя, Оператор вправе направлять административные сообщения, которые могут содержать предупреждения, связанные с безопасностью.
                  </p>
                  <p>
                    <b>3. Условия обработки персональной информации Пользователей и её передачи третьим лицам</b><br/>
                    3.1. Сайт хранит персональную информацию Пользователей в соответствии с внутренними регламентами конкретных сервисов.<br/>
                    3.2. Размещая на Сайте Заказ, Пользователь даёт согласие Оператору на передачу своих персональных данных Поставщикам услуг, агентам и третьим лицам, действующим на основании договора с Оператором для исполнения обязательств перед Пользователем в соответствии с заключенным договором.<br/>
                    3.3. Разглашение информации, полученной Оператором:<br/>
                    3.3.1. Оператор обязуется не разглашать полученную от Пользователя информацию. Не считается разглашением предоставление информации Поставщикам услуг, агентам и третьим лицам, действующим на основании договора с Оператором для исполнения обязательств перед Пользователем.<br/>
                    3.3.2. Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми требованиями закона.<br/>
                    3.4. В отношении персональной информации Пользователя сохраняется её конфиденциальность, кроме случаев, указанных в настоящей Политике, а также добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании отдельных сервисов Пользователь соглашается с тем, что определенная часть его персональной информации становится общедоступной.<br/>
                    3.5. Оператор вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:<br/>
                    3.5.1. Пользователь выразил согласие на такие действия.<br/>
                    3.5.2. Передача необходима для использования Пользователем определённого сервиса либо для исполнения договора с Пользователем.<br/>
                    3.5.3. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.<br/>
                    3.6. Обработка персональных данных Пользователя осуществляется без ограничения срока следующими способами: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств. Обработка персональных данных Пользователей осуществляется в соответствии с Законом о персональных данных.<br/>
                    3.7. При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.<br/>
                    3.8. Оператор принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.<br/>
                    3.9. Оператор совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
                  </p>
                  <p>
                    <b>4. Обязательства Сторон</b><br/>
                    4.1. Пользователь обязан:<br/>
                    4.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.<br/>
                    4.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.<br/>
                    4.2. Оператор обязан:<br/>
                    4.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей Политике.<br/>
                    4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой обработки персональных данных.<br/>
                    4.2.3. Осуществить Блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.<br/>
                    В случае блокирования персональных данных конкретного Пользователя, с момента такой Блокировки персональных данных Пользователь также теряет возможность размещения Заказов на Сайте.
                  </p>
                  <p>
                    <b>5. Ответственность Сторон</b><br/>
                    5.1. Оператор, не исполнивший свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации.<br/>
                    5.2. В случае утраты или разглашения конфиденциальной информации Оператор не несёт ответственности, если данная конфиденциальная информация:<br/>
                    5.2.1. Стала публичным достоянием до её утраты или разглашения.<br/>
                    5.2.2. Была получена от третьей стороны до момента её получения Оператором.<br/>
                    5.2.3. Была разглашена с согласия Пользователя.<br/>
                    5.2.4. Была разглашена на Сайте самим Пользователем.<br/>
                    5.3. Оператор не несёт ответственности за сведения, предоставленные Пользователем на Сайте в общедоступной форме.
                  </p>
                  <p>
                    <b>6. Разрешение споров</b><br/>
                    6.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и Оператором, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).<br/>
                    6.2. Получатель претензии в течение 10 рабочих дней со дня получения претензии письменно уведомляет заявителя претензии о результатах рассмотрения претензии.<br/>
                    6.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим законодательством Российской Федерации.<br/>
                    6.4. К настоящей Политике обработки персональных данных и отношениям между Пользователем и Оператором применяется действующее законодательство Российской Федерации.
                  </p>
                  <p>
                    <b>7. Дополнительные условия</b><br/>
                    7.1. Оператор вправе вносить изменения в настоящую Политика обработки персональных данных без согласия Пользователя.<br/>
                    7.2. Новая Политика обработки персональных данных вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики.<br/>
                    7.3. Все предложения или вопросы по настоящей Политике обработки персональных данных следует сообщать <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a>.<br/>
                    7.5. Настоящая Политика обработки персональных данных является неотъемлемой частью Публичной оферты (далее – Пользовательское соглашение), размещенной на сайте в сети Интернет по адресу: <a href="https://podcastbery.ru/">https://podcastbery.ru/</a>.
                  </p>
                  <p>
                    <b>Реквизиты Оператора:</b><br/>
                    ИП КАЗАНЦЕВ ВЛАДИСЛАВ ДМИТРИЕВИЧ<br/>
                    ИНН 245210737200, ОГРИП 318246800016960<br/>
                    <a href="mailto:info@podcastbery.ru">info@podcastbery.ru</a><br/>
                    <a href="tel:+79831402030">+7 (983) 140-20-30</a>
                  </p>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Policy)